<template>
    <div>

      <div style="width: 100%; display: flex; justify-content: space-around; align-items: center;">
        <div style="text-align: center;">
        <router-link to="/attendance" style="font-size: small;">
          <img src="../assets/calendar.png" style="width: 30%;">
          <p>
            보고서 작성
          </p>
        </router-link> 
        </div>
        <div style="text-align: center;">
        <router-link to="/studentlist" style="font-size: small;">
          <img src="../assets/list.png" style="width: 30%;">
          <p style="font-size: small;">셀원 목록</p>
           </router-link> 
        </div>
        <div style="text-align: center;">
        <router-link to="/attendallcount" style="font-size: small;">
          <img src="../assets/all.png" style="width: 30%;">
          <p style="font-size: small;">출석현황</p>
           </router-link> 
        </div>
      </div>
      

      <div class="card" style="width: 100%;">


        </div>
      <!--  장기 결석자 -->
      <!-- <div class="card" style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title" style="color: darkred;">장기 결석자<span style="font-size: 11pt;">(3주 이상)</span></h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./notice')"></h6>
          <p class="card-text">
            <table class="table">             
                <tbody>
                  <tr >
                    <td v-if="grade =='99'">
                      <span  >
                        <ul class="list-group" v-for="mlist in groupedItems" v-bind:key="mlist.no">
                          <li class="list-group-item" style="margin-bottom: 7px;">{{mlist[0]["gradename"]}} : <span v-for="slist in mlist" v-bind:key="slist.name">&nbsp;&nbsp;{{slist.name}}</span></li>
                        </ul>


                      </span>                      
                     </td>
                     <td v-else>
                      <span v-for="mlist in absentlist" v-bind:key="mlist.no">{{ mlist.name }}&nbsp;&nbsp;</span>                      
                     </td>                     
                  </tr>               
                </tbody>
              </table>
  
          </p>
        </div>
      </div> -->
      <p></p>
<!--  부서 공지 -->
      <div class="card" style="width: 100%;">
        <div class="card-body" style="font-size: 14px;">
          <h5 class="card-title" style="font-size: 16px;"><i class="fa-solid fa-volume-up"></i>  부서 공지</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./notice')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>                 
                <thead class="table-dark">
                  <tr>
                    <th scope="col-sm-1">No</th>
                    <th scope="col-sm-10">제목</th>
                    <th scope="col-sm-1" >일자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in nlists" v-bind:key="list.no" @click="detailView(list)">
                    <th scope="row">{{idx+1}}</th>
                    <td>{{ list.subject.substring(0,30) }}</td>
                    <td>{{ formatDate(list.wdate) }}</td>
                  </tr>               
                </tbody>
              </table>
  
          </p>
        </div>
      </div>
      <!-- 전체 공지 -->
      <p></p>
      <div class="card" style="width: 100%;">
        <div class="card-body" style="font-size: 14px;">
          <h5 class="card-title" style="font-size: 16px;"><i class="fa-solid fa-bell"></i> 전체 공지</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./allnotice')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>                 
                <thead class="table-dark">
                  <tr>
                    <th scope="col-sm-1">No</th>
                    <th scope="col-sm-10">제목</th>
                    <th scope="col-sm-1" >일자</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in allists" v-bind:key="list.no" @click="detailView(list)">
                    <th scope="row">{{idx+1}}</th>
                    <td>{{ list.subject.substring(0,30) }}</td>
                    <td>{{ formatDate(list.wdate) }}</td>
                  </tr>               
                </tbody>
              </table>
  
          </p>
        </div>
      </div>
      <p></p>      
      <div class="card" style="width: 100%;">
        <div class="card-body" style="font-size: 14px;">
          <h5 class="card-title" style="font-size: 16px;"><i class="fa-solid fa-file-import"></i> 셀자료</h5>
          <h6 class="card-subtitle mb-2 text-muted" style="text-align: right;" @click="this.$router.push('./study')">more</h6>
          <p class="card-text">
            <table class="table">
              <colgroup>
                <col width=10%>
                <col width=60%>
                <col width=30%>
            </colgroup>              
              <thead class="table-dark">
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">제목</th>
                  <th scope="col">일자</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, idx)  in slists" v-bind:key="list.no" @click="detailView(list)">
                  <th scope="row">{{idx + 1}}</th>
                  <td>{{ list.subject.substring(0,30) }}</td>
                  <td>{{ formatDate(list.wdate) }}</td>
                </tr>            
              </tbody>
            </table>
  
          </p>
        </div>
      </div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ items.subject }}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- v-if로 items가 존재할 때만 modal-body를 렌더링 -->
      <div class="modal-body">
                      <div v-if="items && items.contents" v-html="getContent(items.contents)"></div>
                      <!-- Display file download link if a file is present -->
                      <template v-if="items.files">
                          <a :href="getFileUrl(items.files)" download>
                              다운로드 파일
                          </a>
                        </template> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">닫기</button>
      </div>
    </div>
  </div>
</div>


  
    </div>
  </template>
  
  <script>
    import manageService from "../services/manageService";
    import dayjs from 'dayjs';
    import $ from "jquery";    
    import '@fortawesome/fontawesome-free/css/all.css';
    import '@fortawesome/fontawesome-free/js/all.js';

    import { getMessaging, getToken  } from "firebase/messaging";

    export default {
        component: {
            dayjs
        },        
      data() {
        return {
          items: {}, // 빈 객체로 초기화          
          nlists: {},
          slists: {},   
          allists: {},       
          id: this.$store.state.id,
          grade: this.$store.state.grade,
          phone: this.$store.state.phone,
          name: this.$store.state.name,
          teacherid: this.$store.state.teacherid,
          role: this.$store.state.role,
          token: this.$store.state.token,  
          classid: this.$store.state.classid,    
          absentlist: {},    
          absentlist2: {},        
        }
      },
      computed:{
        isAuthenticated() {
          return this.$store.state.isAuthenticated;
         },        
        formatDate(){
            return (v)=>{
              return dayjs(v).format("YY.MM.DD");
              }
           },          
           groupedItems() {
            const result = Array.isArray(this.absentlist2);

            if(result){
              return this.absentlist2.reduce((groups, item) => {
                const group = groups[item.gradename] || [];
                group.push(item);
                groups[item.gradename] = group;
                return groups;
              }, {});
            } else {
              return null;
            }


          }           
     },          
      methods:{
        getContent(text){
            if(text){
                return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
            }
        },          
            detailView(item){
              if (item) {
                this.items = item;
                console.log(this.items); // 콘솔에 items 데이터 출력
                $("#exampleModal").modal();
              }
            },
            getData(){
              const data = {
                classid: this.classid,
              };
              manageService
                  .getboardDashboard(data)
                  .then((response) => {

                  this.nlists = response.data.notice;
                  this.slists = response.data.study;
                  this.allists = response.data.allnotice;
                  })
                  .catch((e) => {
                  console.log(e);
                  });
            },

            getAbsent(){
              const data = {
                classid: this.classid,
                grade: this.grade,
              };
              manageService
                  .getAbsentAll(data)
                  .then((response) => {
                    //console.log(response);
                  this.absentlist = response.data;     
                  this.absentlist2 = response.data; 
                  })
                  .catch((e) => {
                  console.log(e);
                  });
            },
          RegistFcmToken(){
            // FCM 설정
            const messaging = getMessaging();

            getToken(messaging, { vapidKey: 'BKUbGoOe9UBTxIbCSKuQnACE25UGZ5J5rV11qE6nAuNVgiythp5-oymMgM9RcX8r6iTNA6T_77Cg13Bq-xReuBE' }).then((currentToken) => {
              if (currentToken) {
                console.log('FCM Token:', currentToken);
                // FCM 토큰을 서버로 전송하거나 필요에 따라 처리

                const data = {
                 phone: this.phone,
                 token: currentToken,
                };
              manageService
                  .setFcmToken(data)
                  .then((response) => {
                    console.log("setFcmToken==", response);
                  })
                  .catch((e) => {
                  console.log(e);
                  });

              } else {
                console.warn('No registration token available. Request permission to generate one.');
              }
            }).catch((err) => {
              console.error('An error occurred while retrieving token. ', err);
            });
          },
          getFileUrl(fileName) {

                let uploadDir = "";
                const serverAddress = '119.205.233.48';
                  // Determine upload directory based on server address
                  if (window.location.hostname === serverAddress) {
                    uploadDir = 'https://manna.donganyouth.com/upload/files/'; // Linux server path
                  } else {
                    //uploadDir =  'D:\\work\\vue_work\\youthmanna\\www\\upload\\files\\'; // Windows server path
                    uploadDir = 'https://manna.donganyouth.com/upload/files/'; // Linux server path
                  }

                  // Replace with your actual upload directory path
                  //let uploadDir = '/path/to/your/upload/directory/';

                  // Construct full file path
                  let fileUrl = uploadDir + fileName;

                  return fileUrl;
                },             
      },
      created() {
        this.getData();   
        this.getAbsent();   
        this.RegistFcmToken();
        //console.log("getisLogin",this.$store.state);  
    },      
    }

  </script>
  <style scoped>
    .table-dark {
    color: #000;
    background-color: #007bff80;
   }
   .table td, .table th {
    padding: 0.5rem;
   }
   .icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.icon img {
  margin-bottom: 10px;
}
  </style>